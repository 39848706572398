import React from 'react';

import { PowerBiWorkspace, PowerBiReport, PowerBiDataset } from './models/PowerBiModels';

export interface AppContextData {
    workspaces?: PowerBiWorkspace[];
    currentWorkspaceId?: string;
    currentWorkspaceName?: string;
    currentWorkspaceIsReadOnly?: boolean;
    currentWorkspaceIsPremium?: boolean;    
    reports?: PowerBiReport[];
    currentReportId?: string;
    datasets?: PowerBiDataset[];
    workspaceArtifactsLoading?: boolean
    onboardingAccount?: boolean
}

export const AppContextDataDefaults: AppContextData = {
    currentWorkspaceId: null,
    currentWorkspaceName: null,
    currentWorkspaceIsReadOnly: null,
    currentWorkspaceIsPremium: null,
    workspaces: null,
    reports: null,
    currentReportId: null,
    datasets: null,
    workspaceArtifactsLoading: false,
    onboardingAccount: false
}

export interface AppContextProps {
    appContextData: AppContextData;
    setCurrentWorkspaceId: (WorkspaceId: string) => void;
    setCurrentReportId: (WorkspaceId: string) => void;
    refreshReportList: () => void;
    reset: () => void;
  }

  export const AppContext = React.createContext<AppContextProps>({
    appContextData: AppContextDataDefaults,
    setCurrentWorkspaceId: (WorkspaceId: string) => {},
    setCurrentReportId: (ReportId: string) => {},
    refreshReportList: () => {},
    reset: () => {}
  });