import React, { forwardRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { isBoolean } from 'lodash';

import { isPathExternal, generateLink } from '../../../utils/paths';

const Anchor = forwardRef(
  (
    {
      to,
      path,
      pathParams,
      target,
      state,
      bypassRouter,
      onClick,
      preventDefault,
      children,
      'data-testid': dataTestId,
      ...props
    },
    ref,
  ) => {
    const navigate = useNavigate();

    const isExternal = useMemo(() => {
      if (!to || (isBoolean(bypassRouter) && !bypassRouter)) return false;
      if (bypassRouter) return true;

      return isPathExternal(to);
    }, [to, bypassRouter]);

    const href = useMemo(() => {
      if (!to && !path) return null;
      if (to) return to;

      return generateLink(path, pathParams);
    }, [to, path, pathParams]);

    const rel = useMemo(() => {
      return isExternal || target === '_blank' ? 'noopener noreferrer' : null;
    }, [isExternal, target]);

    const handleClick = (event) => {
      if (isBoolean(preventDefault) ? preventDefault : !isExternal) {
        event.preventDefault();
      }

      if (!isExternal) navigate(href);
      if (onClick) onClick(event);
    };

    return (
      <a
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        href={href}
        target={target}
        rel={rel}
        onClick={handleClick}
        data-testid={dataTestId}
      >
        {children}
      </a>
    );
  },
);

export default Anchor;
