import {useContext, useEffect} from 'react';
import {
  Box,
  Grid
} from '@mui/material';
import {AppContext} from "../../AppContext";
import Report from "./Report";

const Reports = () => {
  const { appContextData } = useContext(AppContext);
  
  useEffect(() => {
  }, [appContextData]);
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 0}}>
      <Grid container>
        <Report workspaceId={appContextData?.currentWorkspaceId} reportId={appContextData?.currentReportId} />    
      </Grid>
    </Box>
  );
}

export default Reports;
