import React, {useContext} from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent, InputLabel, Grid
} from '@mui/material';
import {PowerBiWorkspace} from "../../models/PowerBiModels";
import {AppContext} from "../../AppContext";

const ReportSelector= () => {
  const { appContextData, setCurrentWorkspaceId , setCurrentReportId} = useContext(AppContext);

  const onUpdateCurrentWorkspace = (event: SelectChangeEvent<string>) => {
    const workspaceId = event.target.value;
    setCurrentWorkspaceId(workspaceId);
  }

  const onUpdateCurrenReport = (event: SelectChangeEvent<string>) => {
    const reportId = event.target.value;
    setCurrentReportId(reportId);
  }

  return ( <>
    {
      !appContextData.onboardingAccount &&
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Box sx={{
              display: "flex",
              justifyContent: {lg: "flex-end", xs: "center"}
            }}>
              <FormControl sx={{ m: 1, maxWidth: {lg: 450, md: 200} }} size="small">
                <InputLabel id="workspace">Select workspace</InputLabel>
                <Select
                  labelId="workspace"
                  variant='standard'
                  autoWidth
                  value={appContextData?.currentWorkspaceId ?? ""}
                  onChange={onUpdateCurrentWorkspace}
                >
                  {appContextData.workspaces && appContextData.workspaces.map((workspace: PowerBiWorkspace) => (
                    <MenuItem value={workspace.id} key={workspace.id}>{workspace.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} >
            <Box sx={{
              display: "flex",
              justifyContent: {lg: "flex-start", xs: "center"}
            }}>
              <FormControl sx={{ m: 1, maxWidth: {lg: 450, md: 200} }} size="small">
                <InputLabel id="report">Select report</InputLabel>
                <Select
                  labelId="report"
                  variant='standard'
                  autoWidth
                  value={appContextData?.currentReportId ?? ""}
                  onChange={onUpdateCurrenReport}
                >
                  {appContextData.reports && appContextData.reports.map((report) => (
                    <MenuItem value={report.id} key={report.id}>{report.name} {report.reportType === "PaginatedReport" ? "(Paginated Report)" : ""}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      }
  </>);
}

export default ReportSelector;
