import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";
import {Box, Button, Grid, IconButton, Typography} from '@mui/material';
import { PowerBiLoginRequest } from "../AuthConfig";
import LoginIcon from '@mui/icons-material/Login';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const LoginMenu = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const loginUser = () => {
    instance.loginPopup(PowerBiLoginRequest);
  };

  const logoutUser = () => {
    navigate("/");
    instance.logoutPopup();
  };

  if (isAuthenticated) {
    return (
      <Box sx={{ marginLeft: "auto" }}>
        <Grid container>
          <Grid item sx={{ padding: "10px" }} display="flex" justifyContent="center" flexDirection="column">
            <Typography sx={{ color: 'text.primary', fontWeight: 600 }}>{account?.name}</Typography>
          </Grid>
          {/* <Grid item sx={{ padding: "4px" }}>
            <IconButton aria-label="account" size="small" onClick={() => navigate("profile")}>
              <AccountBoxIcon fontSize="large" sx={{ color: 'text.primary', fontWeight: 600 }} />
            </IconButton>
          </Grid> */}
          <Grid item sx={{ padding: "4px" }}>
            <IconButton aria-label="account" size="small" onClick={() => logoutUser()}>
              <PowerSettingsNewIcon fontSize="large" sx={{ color: 'text.primary', fontWeight: 600, padding: 0 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    );
  }
  else {
    return (
      <Box sx={{ marginLeft: "auto", marginRight: "12px", pt: "2px" }}>
        <Button
          onClick={loginUser}
          color="inherit"
          startIcon={<LoginIcon />}
          sx={{ backgroundColor: 'text.primary', "&:hover": {
              backgroundColor: 'text.secondary'
            } }}
        >
          Login
        </Button>
      </Box>
    );

  }
}

export default LoginMenu;
