import { Route, Routes } from "react-router-dom";
import Banner from './Banner';
import Profile from './pages/Profile';
import PageNotFound from './PageNotFound';
import { AppContext } from "../AppContext";

import { Grid } from '@mui/material';
import classnames from "classnames";
import { AsideNavigation } from "./navigations";
import { useMemo, useState, useContext } from "react";
import { MENU_TYPES } from "../constants/type";
import {
  PORTAL_ROUTE
} from "../constants/route";

// @ts-ignore
import styles from './PageTemplate.module.scss';
import { useIsAuthenticated } from "@azure/msal-react";
import Reports from "./pages/Reports";
import DataLoading from "./DataLoading";

const PageLayout = () => {

  const [asideOpen, setAsideOpen] = useState(true);

  const items = useMemo(
    () => [
      {
        id: MENU_TYPES.HOME,
        label: 'Back to Portal',
        icon: 'p',
        to: PORTAL_ROUTE,
      },
    ],
    [],
  );

  const navigationItems = useMemo(() => items, [items]);
  const isAuthenticated = useIsAuthenticated();
  const handleAsideToggle = () => setAsideOpen(!asideOpen);
  const { appContextData } = useContext(AppContext);
  return (
    <>
      <Banner />
      <div
        className={classnames(styles.PageTemplate)}
        data-testid="PageTemplate"
      >
        <Grid
          className={classnames(styles.contentRow)}
          container
        >
          {isAuthenticated &&

            <Grid item className={styles.nav}>
              <AsideNavigation
                user={null}
                items={navigationItems}
                open={asideOpen}
                onToggle={handleAsideToggle}
              />
            </Grid>
          }

          {appContextData.onboardingAccount && 
          
            < Grid
              className={classnames(styles.content)}
              item xs><DataLoading />
            </Grid>
          }

          {!appContextData.onboardingAccount &&
          
            < Grid
              className={classnames(styles.content)}
              item
              xs>
              <Routes>
                <Route path="/" element={<Reports />} />
                <Route path="*" element={<PageNotFound />} />
                <Route path="profile" element={<Profile />} />
              </Routes>
            </Grid>
          }
        </Grid>
      </div>
    </>
  )
}

export default PageLayout
