import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import {useResolvedPath, matchPath, useLocation} from 'react-router-dom';
import {
  flattenDeep,
  isArray,
  intersection,
  reject,
  isNil,
  isString,
} from 'lodash';

import mergeClassNames from '../../../utils/classnames';
import { Trigger } from '../../../triggers';

import styles from './AsideNavigationItem.module.scss';
import {matchRoutes} from "react-router";

const AsideNavigationItem = ({
  className,
  classNames,
  user,
  label,
  icon,
  iconClassName,
  to,
  target,
  items,
  openPath,
  active,
  permissions: requiredPermissions,
  roles: requiredRoles,
  'data-testid': dataTestId,
}) => {
  const classes = useMemo(
    () =>
      mergeClassNames(styles, classNames, {
        AsideNavigationItem: className,
      }),
    [className, classNames],
  );

  const getRefs = useCallback((ref, refs) => {
    if (!isArray(refs) || !refs.length) return ref;
    return flattenDeep([ref, refs.map((item) => getRefs(item.to, item.items))]);
  }, []);

  const hasItems = useMemo(() => isArray(items) && items.length, [items]);

  const allPaths = useMemo(
    () => [!hasItems ? to : getRefs(to, items), openPath],
    [hasItems, to, items, openPath, getRefs],
  );

  const location = useLocation()
  const match = matchRoutes([{path: to}], location);
  const matchAllPaths = false;

  const dropdownClassNames = [
    classes.dropdown,
    { [classes.open]: matchAllPaths },
  ];

  const rootClassName = classnames(
    classes.AsideNavigationItem,
    { [classes.active]: to && (match || matchAllPaths || active) },
    hasItems && dropdownClassNames,
  );

  const renderItem = useCallback(
    (item) => <AsideNavigationItem key={item.label} {...item} user={user} />,
    [user],
  );

  const renderedItems = useMemo(() => {
    if (!hasItems) return null;
    return <section className={classes.items}>{items.map(renderItem)}</section>;
  }, [hasItems, items, classes.items, renderItem]);

  return (
    <div className={rootClassName} data-testid={dataTestId}>
      <Trigger
        className={[classes.trigger, iconClassName]}
        to={to}
        target={target}
        data-icon={icon}
      >
        <span className={classes.label}>{label}</span>
      </Trigger>
      {renderedItems}
    </div>
  );
};

export default AsideNavigationItem;
