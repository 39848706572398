import { generatePath } from 'react-router';
import { externalUrlRegex } from '../../constants/regex';

export const isPathExternal = (path) => externalUrlRegex.test(path);

export const generateLink = (path, params) => {
  try {
    return generatePath(path, params) || '/';
  } catch (error) {
    return null;
  }
};
