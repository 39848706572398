import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';

import { useState, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";

import { useIsAuthenticated } from "@azure/msal-react";

import PageLayout from './components/PageLayout'
import { Box } from '@mui/material';
import { AppContext } from "./AppContext";
import { PowerBiWorkspace, PowerBiReport } from './models/PowerBiModels';
import PowerBiService, { MyWorkspace } from './services/PowerBiService';
import ThemeProvider from "./theme";
import history from './routingHistory';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTSID,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history }
    }
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const [workspaces, setWorkspaces] = useState<PowerBiWorkspace[] | null>(null);
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>(null);
  const [currentWorkspaceName, setCurrentWorkspaceName] = useState<string>(null);
  const [currentWorkspaceIsReadOnly, setCurrentWorkspaceIsReadOnly] = useState<boolean>(null);
  const [currentWorkspaceIsPremium, setCurrentWorkspaceIsPremium] = useState<boolean>(null);
  const [reports, setReports] = useState<PowerBiReport[] | null>(null);
  const [currentReportId, setCurrentReportId] = useState<string>(null);
  const [workspaceArtifactsLoading, setWorkspaceArtifactsLoading] = useState<boolean>(false);
  const [onboardingAccount, setOnboardingAccount] = useState<boolean>(false);

  const getPowerBiDataAsync = async () => {

    if (isAuthenticated) {      
      let powerBiWorkspaces: PowerBiWorkspace[] = workspaces;
      if (!workspaces) {
        powerBiWorkspaces = await PowerBiService.GetWorkspaces();
        
        if (powerBiWorkspaces && powerBiWorkspaces.length == 0){
          setOnboardingAccount(true);
          powerBiWorkspaces = await PowerBiService.GetWorkspacesWithRetry(120); 
          setOnboardingAccount(false);
        }

        if (powerBiWorkspaces) {
          powerBiWorkspaces = powerBiWorkspaces.sort((a, b) => {
            let nameA = a.name.toLocaleLowerCase()
            let nameB = b.name.toLocaleLowerCase()
            if (nameA < nameB){
              return -1;
            }
            if (nameA > nameB){
              return 1;
            }
  
            return 0;
          })
        }

        setWorkspaces(powerBiWorkspaces.filter((workspace) => !(workspace.name.includes(JSON.parse(process.env.REACT_APP_INVALID_WORKSPACES)))));
      }

      if(!currentWorkspaceId){
        return;
      }

      let workspace: PowerBiWorkspace = (currentWorkspaceId === MyWorkspace.id) ?
        MyWorkspace :
        powerBiWorkspaces.find(workspace => workspace.id === currentWorkspaceId);

      setCurrentWorkspaceName(workspace.name);
      setCurrentReportId(null);
      setCurrentWorkspaceIsReadOnly(workspace.isReadOnly);
      setCurrentWorkspaceIsPremium(workspace.isOnDedicatedCapacity);
      setWorkspaceArtifactsLoading(true);
      var reports = await PowerBiService.GetReports(currentWorkspaceId);
      if (reports) {
        reports = reports.sort((a, b) => {
          let nameA = a.name.toLocaleLowerCase()
          let nameB = b.name.toLocaleLowerCase()
          if (nameA < nameB){
            return -1;
          }
          if (nameA > nameB){
            return 1;
          }

          return 0;
        })
      }

      setReports(reports);
      setCurrentReportId(reports[0].id);
      await new Promise(f => setTimeout(f, 1000));
      setWorkspaceArtifactsLoading(false);
    }
    else {
      setWorkspaces(null);
      setReports(null);
    }
  }

  useEffect(() => {
    getPowerBiDataAsync();
  }, [currentWorkspaceId]);


  useEffect(() => {
    if (workspaces && workspaces.length > 0) {
      setCurrentWorkspaceId(workspaces[0].id);
    }
  }, [workspaces]);

  useEffect(() => {
    if (isAuthenticated) {
      reset();
      getPowerBiDataAsync();
    }
  }, [isAuthenticated]);


  const refreshReportList = () => {
    const refreshReportListAsync = async () => {
      setReports(await PowerBiService.GetReports(currentWorkspaceId));
    };
    refreshReportListAsync();
  };

  const reset = async () => {
    setWorkspaces(null);
    setReports(null);
    setCurrentReportId(null);
    setCurrentWorkspaceId(null);

    const refreshUserPermissionsAsync = async () => {
      var item = localStorage.getItem('refreshUserPermissions');
      if (!item) {
        await PowerBiService.RefreshUserPermissions();
        localStorage.setItem("refreshUserPermissions", new Date().toLocaleString());
      } 
    };

    refreshUserPermissionsAsync();
  };

  return (
    <AppInsightsErrorBoundary onError={() => <div style={{ padding: "10px" }}><Box component="img" src="/lineage-logo.png" sx={{ width: "172px" }}/><h4>Something went wrong</h4></div>} appInsights={reactPlugin}>
      <AppContext.Provider value={{
        appContextData: {
          currentWorkspaceId: currentWorkspaceId,
          currentWorkspaceName: currentWorkspaceName,
          currentWorkspaceIsReadOnly: currentWorkspaceIsReadOnly,
          currentWorkspaceIsPremium: currentWorkspaceIsPremium,
          workspaces: workspaces,
          reports: reports,
          currentReportId: currentReportId,
          workspaceArtifactsLoading: workspaceArtifactsLoading,
          onboardingAccount: onboardingAccount
        }, setCurrentWorkspaceId, refreshReportList, setCurrentReportId, reset
      }}>

        <ThemeProvider>
          <BrowserRouter>
            <PageLayout />
          </BrowserRouter>
        </ThemeProvider>

      </AppContext.Provider>
    </AppInsightsErrorBoundary>
  )
}

export default App;
