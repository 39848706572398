import { TRANSFER_TYPES } from '../type';

export const PORTAL_ROUTE = process.env.REACT_APP_PORTAL_ROUTE;
export const ROOT_ROUTE = '/';
export const API_ROUTE = '/api';
export const INDEX_HTML_ROUTE = 'index.html';
export const ADD_ROUTE = '/add';
export const AUTH_ROUTE = '/auth';
export const LOGIN_ROUTE = '/login';
export const ADMINISTRATION_ROUTE = '/administration';
export const SIGN_OUT_ROUTE = '/signout';
export const ARTICLES_ROUTE = '/items';
export const CREATE_ROUTE = '/create';
export const UPDATE_ROUTE = '/update';
export const DELETE_ROUTE = '/delete';
export const CSV_ROUTE = '/csv';
export const EXCEL_ROUTE = '/excel';
export const PDF_ROUTE = '/pdf';
export const PRINT_ROUTE = '/print';
export const AZURE_AD_ROUTE = '/azure-ad';
export const HOME_ROUTE = '/';
export const PROFILE_ROUTE = '/profile';
export const ME_ROUTE = '/me';
export const ORDERS_ROUTE = '/orders';
export const PLANNING_ROUTE = '/planning';
export const ALL_ROUTE = '/all';
export const INTAKE_ROUTE = '/intake';
export const OUTTAKE_ROUTE = '/outtake';
export const CUSTOMERS_ROUTE = '/customers';
export const MANAGE_ROUTE = '/manage';
export const TRUCKS_LATE_ROUTE = '/trucksLate';
export const COUNTRY_ROUTE = '/country';
export const FILTER_ROUTE = '/filters';
export const TRIPS_ROUTE = '/trips';
export const TRANSPORT_COMPANY_ROUTE = '/transportCompany';
export const PLANT_ROUTE = '/plant';
export const CUSTOMER_ROUTE = '/customer';
export const ID_PARAM_ROUTE = '/:id';
export const TRANSPORT_TYPE_ROUTE = '/transportType';
export const ORDER_STATUS_ROUTE = '/orderstatus';
export const LOCK_STATUS_ROUTE = '/lockstatus';
export const PROGRESS_ROUTE = '/progress';
export const TEMPLATE_ROUTE = '/template';
export const TRANSPORT_BOOKING_ROUTE = '/transportBooking';
export const TICKETING_ROUTE = '/ticketing';
export const SSCC_ROUTE = '/sscc';
export const STOCKS_ROUTE = '/stocks';
export const SITES_ROUTE = '/sites';
export const LOCK_ORDER_ROUTE = '/lock_order';
export const CONCEPT_ROUTE = '/concepts';
export const DOCUMENT_TYPE_PARAM_ROUTE = '/:type(all|intake|outtake)';
export const DOCUMENT_TYPE_ALL_PARAM_ROUTE = '/:type(all)';
export const DOCUMENT_TYPE_INTAKE_PARAM_ROUTE = '/:type(intake)';
export const DOCUMENT_TYPE_OUTTAKE_PARAM_ROUTE = '/:type(outtake)';
export const ORDER_NUMBER_PARAM_ROUTE = '/:orderNumber';
export const ORDER_LINE_NUMBER_PARAM_ROUTE = '/:orderLineNumber';
export const INDEX_PARAM_ROUTE = '/:index';
export const ORDER_LINE_INDEX_ROUTE = '/:orderLineIndex';
export const CANCEL_ROUTE = '/cancel';
export const GRAPH_ROUTE = '/graph';
export const LOCK_ROUTE = '/lock';
export const CAPACITY_ROUTE = '/capacity';
export const STATUS_ROUTE = '/status';
export const DOCLINK_ROUTE = '/doclink';
export const TRANSPORTS_ROUTE = '/transports';
export const TRANSPORT_NUMBER_ROUTE = '/:transportNumber';
export const CARRIERS_ROUTE = '/carriers';
export const CONTAINERS_ROUTE = '/containers';
export const ADDRESS_ROUTE = '/address';
export const TRANSFER_ROUTE = '/transfer';
export const DIRECTION_PARAM_ROUTE = '/:direction';
export const TIMESLOT_ROUTE = '/timeslot';
export const TIMESLOT_ENTRIES_ROUTE = '/timeslotentries';
export const TIMESLOT_BOOKING_ROUTE = '/timeslotbooking';
export const TRIP_NUMBER_PARAM_ROUTE = '/:tripNumber';
export const OPERATION_ROUTE = '/operation';
export const POWERBI_ROUTE = '/powerbi';
export const EMBED_TOKEN_ROUTE = '/embedToken';
export const REPORTS_ROUTE = '/reports';
export const EMAIL_NOTIFICATION_ROUTE = '/emailnotification';
export const MAINTENANCE_ROUTE = '/maintenance';
export const TRIP_OVERVIEW_ROUTE = '/tripoverview';
export const CARRIER_LOCATIONS_ROUTE = '/carrierlocations';
export const CONFIGURATION_ROUTE = '/configuration';
export const USER_ID_PARAM_ROUTE = '/:userId';
export const COMPANIES_ON_SITE_ROUTE = '/companiesonsite';
export const USER_WITH_COMPANY_ROUTE = '/userwithcompany';
export const IT_ROUTE = '/it';
export const VARIANT_ROUTE = '/variant';
export const ADDRESS_NAME_ROUTE = '/addressName';
export const UNIQUE_ROUTE = '/unique';
export const EXISTS_ROUTE = '/exists';
export const ITEM_NUMBER_PARAM_ROUTE = '/:itemNumber';
export const APPOINTMENT_ROUTE = '/appointment';
export const UNPROCESSED_ROUTE = '/unprocessed';

export const AUTH_SIGN_OUT_ROUTE = `${AUTH_ROUTE}${SIGN_OUT_ROUTE}`;
export const AUTH_AZURE_AD_ROUTE = `${AUTH_ROUTE}${AZURE_AD_ROUTE}`;
export const API_AUTH_AZURE_AD_ROUTE = `${API_ROUTE}${AUTH_AZURE_AD_ROUTE}`;
export const PROFILE_ME_ROUTE = `${PROFILE_ROUTE}${ME_ROUTE}`;

export const PDF_PRINT_ROUTE = `${PDF_ROUTE}${PRINT_ROUTE}`;

export const ARTICLES_CREATE_ROUTE = `${ARTICLES_ROUTE}${CREATE_ROUTE}`;
export const ARTICLES_CSV_ROUTE = `${ARTICLES_ROUTE}${CSV_ROUTE}`;
export const PROGRESS_INTAKE_ROUTE = `${PROGRESS_ROUTE}${DOCUMENT_TYPE_INTAKE_PARAM_ROUTE}`;
export const PROGRESS_OUTTAKE_ROUTE = `${PROGRESS_ROUTE}${DOCUMENT_TYPE_OUTTAKE_PARAM_ROUTE}`;
export const CUSTOMERS_MANAGE_ROUTE = `${CUSTOMERS_ROUTE}${MANAGE_ROUTE}`;

export const ARTICLES_ITEM_NUMBER_PARAM_ROUTE = `${ARTICLES_ROUTE}${ITEM_NUMBER_PARAM_ROUTE}`;
export const ITEM_NUMBER_PARAM_EXISTS_ROUTE = `${ITEM_NUMBER_PARAM_ROUTE}${EXISTS_ROUTE}`;
export const ARTICLES_ITEM_NUMBER_PARAM_EXISTS_ROUTE = `${ARTICLES_ROUTE}${ITEM_NUMBER_PARAM_EXISTS_ROUTE}`;

export const ORDERS_ALL_ROUTE = `${ORDERS_ROUTE}${ALL_ROUTE}`;
export const ORDERS_INTAKE_ROUTE = `${ORDERS_ROUTE}${INTAKE_ROUTE}`;
export const ORDERS_OUTTAKE_ROUTE = `${ORDERS_ROUTE}${OUTTAKE_ROUTE}`;
export const ORDERS_CSV_ROUTE = `${ORDERS_ROUTE}${CSV_ROUTE}`;
export const ORDERS_ORDER_NUMBER_PARAM_ROUTE = `${ORDERS_ROUTE}${ORDER_NUMBER_PARAM_ROUTE}`;
export const ORDERS_ORDER_NUMBER_PARAM_DOCLINK_ROUTE = `${ORDERS_ORDER_NUMBER_PARAM_ROUTE}${DOCLINK_ROUTE}`;
export const ORDERS_ORDER_NUMBER_PARAM_CANCEL_ROUTE = `${ORDERS_ORDER_NUMBER_PARAM_ROUTE}${CANCEL_ROUTE}`;
export const ORDERS_ORDER_NUMBER_PARAM_EXISTS_ROUTE = `${ORDERS_ORDER_NUMBER_PARAM_ROUTE}${EXISTS_ROUTE}`;
export const ORDERS_DOCUMENT_TYPE_PARAM_ROUTE = `${ORDERS_ROUTE}${DOCUMENT_TYPE_PARAM_ROUTE}`;
export const ORDERS_DOCUMENT_TYPE_ALL_PARAM_ROUTE = `${ORDERS_ROUTE}${DOCUMENT_TYPE_ALL_PARAM_ROUTE}`;
export const ORDERS_DOCUMENT_TYPE_INTAKE_PARAM_ROUTE = `${ORDERS_ROUTE}${DOCUMENT_TYPE_INTAKE_PARAM_ROUTE}`;
export const ORDERS_DOCUMENT_TYPE_OUTTAKE_PARAM_ROUTE = `${ORDERS_ROUTE}${DOCUMENT_TYPE_OUTTAKE_PARAM_ROUTE}`;
export const ORDERS_CONCEPTS_INDEX_PARAM_ROUTE = `${ORDERS_ROUTE}${CONCEPT_ROUTE}${INDEX_PARAM_ROUTE}`;
export const ORDERS_ORDER_UNPROCESSED_ROUTE = `${ORDERS_ROUTE}${UNPROCESSED_ROUTE}`;
export const ORDERS_UNPROCESSED_NUMBER_PARAM_ROUTE = `${ORDERS_ORDER_UNPROCESSED_ROUTE}${ORDER_NUMBER_PARAM_ROUTE}`;

export const ORDERS_PROGRESS_ROUTE = `${ORDERS_ROUTE}${PROGRESS_ROUTE}`;
export const ORDERS_PROGRESS_CSV_ROUTE = `${ORDERS_PROGRESS_ROUTE}${CSV_ROUTE}`;
export const ORDERS_PROGRESS_EXCEL_ROUTE = `${ORDERS_PROGRESS_ROUTE}${EXCEL_ROUTE}`;
export const ORDERS_PROGRESS_TRUCKS_LATE_ROUTE = `${ORDERS_PROGRESS_ROUTE}${TRUCKS_LATE_ROUTE}`;
export const ORDERS_PROGRESS_SSCC_ROUTE = `${ORDERS_PROGRESS_ROUTE}${SSCC_ROUTE}`;

export const ORDERS_PLANNING_ROUTE = `${ORDERS_ROUTE}${PLANNING_ROUTE}`;
export const ORDERS_PLANNING_TRUCKS_LATE_ROUTE = `${ORDERS_PLANNING_ROUTE}${TRUCKS_LATE_ROUTE}`;
export const ORDERS_PLANNING_SSCC_ROUTE = `${ORDERS_PLANNING_ROUTE}${SSCC_ROUTE}`;
export const ORDERS_PLANNING_CARRIERS_ROUTE = `${ORDERS_PLANNING_ROUTE}${CARRIERS_ROUTE}`;

export const CSV_TEMPLATE_ROUTE = `${CSV_ROUTE}${TEMPLATE_ROUTE}`;
export const STOCKS_CREATE_LOCK_ORDER_ROUTE = `${STOCKS_ROUTE}${LOCK_ROUTE}`;
export const STOCKS_CHANGE_QUALITY_STATUS_ROUTE = `${STOCKS_ROUTE}${STATUS_ROUTE}`;

export const TRANSPORTS_ALL_ROUTE = `${TRANSPORTS_ROUTE}${ALL_ROUTE}`;
export const TRANSPORTS_INTAKE_ROUTE = `${TRANSPORTS_ROUTE}${INTAKE_ROUTE}`;
export const TRANSPORTS_OUTTAKE_ROUTE = `${TRANSPORTS_ROUTE}${OUTTAKE_ROUTE}`;
export const TRANSPORTS_TRANSPORT_NUMBER_PARAM_ROUTE = `${TRANSPORTS_ROUTE}${TRANSPORT_NUMBER_ROUTE}`;
export const TRANSPORTS_DOCUMENT_TYPE_ALL_PARAM_ROUTE = `${TRANSPORTS_ROUTE}${DOCUMENT_TYPE_ALL_PARAM_ROUTE}`;
export const TRANSPORTS_DOCUMENT_TYPE_INTAKE_PARAM_ROUTE = `${TRANSPORTS_ROUTE}${DOCUMENT_TYPE_INTAKE_PARAM_ROUTE}`;
export const TRANSPORTS_DOCUMENT_TYPE_OUTTAKE_PARAM_ROUTE = `${TRANSPORTS_ROUTE}${DOCUMENT_TYPE_OUTTAKE_PARAM_ROUTE}`;
export const TRANSPORTS_CONCEPTS_ROUTE = `${TRANSPORTS_ROUTE}${CONCEPT_ROUTE}`;
export const TRANSPORTS_CONCEPTS_TRANSPORT_NUMBER_PARAM_ROUTE = `${TRANSPORTS_CONCEPTS_ROUTE}${TRANSPORT_NUMBER_ROUTE}`;

export const ARTICLE_UPDATE_ROUTE = `${ARTICLES_ROUTE}${UPDATE_ROUTE}`;

export const TRANSFER_PAGE_ROUTE = `${TRANSFER_ROUTE}${DIRECTION_PARAM_ROUTE}`;
export const TRANSFER_INCOMING_ROUTE = `${TRANSFER_ROUTE}/${TRANSFER_TYPES.INCOMING}`;
export const TRANSFER_OUTGOING_ROUTE = `${TRANSFER_ROUTE}/${TRANSFER_TYPES.OUTGOING}`;

export const POWERBI_EMBED_TOKEN_ROUTE = `${POWERBI_ROUTE}${EMBED_TOKEN_ROUTE}`;

// CRM
export const CRM_ROUTE = '/crm';
export const COMPANY_ROUTE = '/company';
export const COMPANIES_ROUTE = '/companies';
export const USER_ROUTE = '/user';
export const USERS_ROUTE = '/users';
export const SITE_GLN_PARAM_ROUTE = '/:siteGLN';
export const COMPANY_REFERENCE_PARAM_ROUTE = '/:companyReference';
export const UPN_PARAM_ROUTE = '/:upn';

// IT
export const WAREHOUSE_LOCATIONS_ROUTE = '/warehouse_locations';
export const WAREHOUSE_DETAIL_ROUTE = `${WAREHOUSE_LOCATIONS_ROUTE}${ID_PARAM_ROUTE}`;
export const WAREHOUSE_CONCEPT_ROUTE = `${WAREHOUSE_LOCATIONS_ROUTE}${CONCEPT_ROUTE}`;

export const COMPANY_OVERVIEW_ROUTE = `${CRM_ROUTE}${COMPANIES_ROUTE}`;
export const COMPANY_DETAIL_ROUTE = `${CRM_ROUTE}${COMPANIES_ROUTE}${COMPANY_REFERENCE_PARAM_ROUTE}`;
export const SITE_OVERVIEW_ROUTE = `${CRM_ROUTE}${SITES_ROUTE}`;
export const SITE_DETAIL_ROUTE = `${CRM_ROUTE}${SITES_ROUTE}${SITE_GLN_PARAM_ROUTE}`;
export const IT_ADMIN_ROUTE = `${CRM_ROUTE}${IT_ROUTE}`;
export const TRIP_OVERVIEW_PAGE_ROUTE = `${OPERATION_ROUTE}${TRIP_OVERVIEW_ROUTE}`;
export const CARRIER_LOCATIONS_PAGE_ROUTE = `${OPERATION_ROUTE}${CARRIER_LOCATIONS_ROUTE}`;

export const SITE_COMPANY_LINK_ROUTE = `${SITE_GLN_PARAM_ROUTE}${COMPANY_ROUTE}${COMPANY_REFERENCE_PARAM_ROUTE}`;
export const SITE_USER_LINK_ROUTE = `${SITE_GLN_PARAM_ROUTE}${USER_ROUTE}${UPN_PARAM_ROUTE}`;
export const COMPANY_USER_LINK_ROUTE = `${COMPANY_REFERENCE_PARAM_ROUTE}${USER_ROUTE}${UPN_PARAM_ROUTE}`;
