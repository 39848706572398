import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';

import AsideNavigationItem from '../AsideNavigationItem';

import styles from './AsideNavigation.module.scss';

const AsideNavigation = ({ user, open, items, onToggle }) => {
  const order = useMemo(() => user?.menu || [], [user?.menu]);

  const handleClickToggle = () => {
    if (onToggle) onToggle();
  };

  const renderItem = useCallback(
    (item) => {
      if (!item) return null;

      return (
        <AsideNavigationItem
          key={item.label}
          user={user}
          label={item.label}
          to={item.to}
          target={item.target}
          icon={item.icon}
          iconClassName={item.iconClassName}
          items={item.items}
          active={item.active}
          openPath={item.openPath}
          permissions={item.permissions}
          roles={item.roles}
        />
      );
    },
    [user],
  );

  const renderedItems = useMemo(() => {
    if (!Array.isArray(items) || !items.length) return null;

    const handleIndex = (item) => {
      const index = order.indexOf(item);
      return index === -1 ? 999 : index;
    };

    const byMenu = ({ id: x }, { id: y }) => handleIndex(x) - handleIndex(y);

    return [...items].sort(byMenu).map(renderItem);
  }, [items, order, renderItem]);

  const classNames = classnames(styles.AsideNavigation, {
    [styles.open]: open,
  });

  return (
    <aside className={classNames} data-testid="AsideNavigation">
      <button
        type="button"
        label="toggle"
        className={styles.toggle}
        onClick={handleClickToggle}
      />
      <section className={styles.content}>
        <nav className={styles.nav}>{renderedItems}</nav>
        <section className={styles.footer}>
          <span>{`version ${1}`}</span>
          <span className={styles.poweredBy}>
            Powered by
            <br />
            Lineage
          </span>
        </section>
      </section>
    </aside>
  );
};

export default AsideNavigation;
