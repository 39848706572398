import classnames from 'classnames';
import { mergeWith } from 'lodash';

const customizer = (className, additionalClassName) => {
  return classnames(className, additionalClassName);
};

const mergeClassNames = (classNames, ...additionalClassNames) => {
  return mergeWith({}, classNames, ...additionalClassNames, customizer);
};

export default mergeClassNames;
