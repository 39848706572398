import React from 'react';
import { Box, CircularProgress, Typography} from '@mui/material';

const indicatorSize = 80;
const DataLoading = () => {
  return (
    <Box sx={{ width: "100%", height: "100%", backgroundColor: "transparant", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress
             className='circularProgress'/>
        <Typography sx={{ marginTop: 2 }}>Please wait, we are preparing your reports on the background for the first time.</Typography>
      </Box>
    </Box>
  );
}

export default DataLoading;
