import {AppBar, Box, Grid, Toolbar} from '@mui/material';
import Login from './LoginMenu';
import ReportSelector from './pages/ReportSelector';
import { useLocation } from "react-router-dom";
import {useIsAuthenticated} from "@azure/msal-react";

const Banner = () => {
  const { pathname } = useLocation();
  const isAuthenticated = useIsAuthenticated();
  return (
    <AppBar position="static" sx={{ bgcolor: "#fff" }}>
      <Toolbar disableGutters sx={{mt: 2, mb: 2}}>
        <Grid container sx={{display: { xs: 'none', md: 'flex' }}}>
          <Grid
            item
            sx={{
              mr: 1,
              ml: 1,
              left: 0,
              width: '172px'
            }}
            display="flex"
            direction="row"
            alignItems="center"
          >
            <Box
              component="img"
              src="/lineage-logo.png"
              sx={{
                mr: 1,
                ml: 1,
                left: 0,
                width: '172px'
              }}/>
          </Grid>
          <Grid item sx={{ flexGrow: 1}}>
            {isAuthenticated && pathname==="/" && <ReportSelector/>}
          </Grid>
          <Grid item >
            <Login/>
          </Grid>
        </Grid>
        <Grid container sx={{display: { xs: 'flex', md: 'none' }}}>
          <Grid
            item
            xs={6}
            sx={{
              left: 0,
            }}
            display="flex"
            direction="row"
            alignItems="center"
          >
            <Box
              component="img"
              src="/lineage-logo.png"
              sx={{
                ml: 1,
                left: 0,
                width: '172px'
              }}/>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Login/>
          </Grid>
          <Grid item sx={{ flexGrow: 1}} xs={12}>
            {isAuthenticated && pathname==="/" && <ReportSelector/>}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Banner;
