export const ALL_STATE_TYPE = '';
export const NEW_STATE_TYPE = 'NEW';
export const UPDATE_STATE_TYPE = 'UPDATE';
export const DRAFT_STATE_TYPE = 'DRAFT';
export const AVAILABLE_STATE_TYPE = 'AVAILABLE';
export const BLOCKED_STATE_TYPE = 'BLOCKED';

export const STATE_TYPES = [
  ALL_STATE_TYPE,
  NEW_STATE_TYPE,
  UPDATE_STATE_TYPE,
  DRAFT_STATE_TYPE,
  AVAILABLE_STATE_TYPE,
  BLOCKED_STATE_TYPE,
];

export const TEXT_INPUT_TYPE = 'text';
export const NUMBER_INPUT_TYPE = 'number';
export const SELECT_INPUT_TYPE = 'select';
export const AUTOCOMPLETE_INPUT_TYPE = 'autocomplete';
export const FREESOLO_INPUT_TYPE = 'freesolo';
export const DATE_INPUT_TYPE = 'date';
export const TIME_INPUT_TYPE = 'time';
export const DATE_TIME_INPUT_TYPE = 'datetime-local';
export const DATE_DIALOG_INPUT_TYPE = 'date-dialog';

export const INPUT_TYPES = [
  TEXT_INPUT_TYPE,
  NUMBER_INPUT_TYPE,
  SELECT_INPUT_TYPE,
  AUTOCOMPLETE_INPUT_TYPE,
  FREESOLO_INPUT_TYPE,
  DATE_INPUT_TYPE,
  TIME_INPUT_TYPE,
  DATE_TIME_INPUT_TYPE,
  DATE_DIALOG_INPUT_TYPE,
];

export const PDF_FILE_TYPE = 'PDF';
export const PDF_PRINT_FILE_TYPE = 'PDF_PRINT';
export const EXCEL_FILE_TYPE = 'EXCEL';
export const CSV_FILE_TYPE = 'CSV';
export const CSV_TEMPLATE_FILE_TYPE = 'CSV_TEMPLATE';

export const FILE_TYPES = [
  PDF_FILE_TYPE,
  PDF_PRINT_FILE_TYPE,
  EXCEL_FILE_TYPE,
  CSV_FILE_TYPE,
  CSV_TEMPLATE_FILE_TYPE,
];

export const ORDER_TYPES = {
  ALL: 'All',
  INTAKE: 'Intake',
  OUTTAKE: 'Outtake',
};

export const ORDER_VARIANTS = {
  INTAKE: {
    STANDARD: 'STANDARD',
    ENTREPOT: 'ENTREPOT',
    RETURN: 'RETURN',
  },
  OUTTAKE: {
    STANDARD: 'STANDARD',
    ENTREPOT: 'ENTREPOT',
    SAMPLE: 'SAMPLE',
    DESTRUCT: 'DESTRUCT',
  },
};

export const FLAT_ORDER_VARIANTS = [
  ...new Set(Object.values(ORDER_VARIANTS).flatMap((x) => Object.values(x))),
];

export const INTAKE_ORDER_VARIANTS = Object.values(ORDER_VARIANTS.INTAKE);
export const OUTTAKE_ORDER_VARIANTS = Object.values(ORDER_VARIANTS.OUTTAKE);

export const ORDER_STATUS_OPTIONS = [
  'Draft',
  'Plannable',
  'Planned',
  'Arrived',
  'In Progress',
  'Processed',
  'Completed',
];
export const ORDER_STATUS_CANCELED = 'Canceled';
export const ORDER_STATUS_COMPLETED = 'Completed';
export const EMPTY_FIELD_VALUE = 'N/A';

export const CANCELABLE_ORDER_STATUS = [
  'draft',
  'plannable',
  'concept',
  'not planned',
];

export const FEEDBACK = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const BUTTON = {
  PRIMARY: 'primary',
  LIGHT: 'light',
  ERROR: 'error',
  HIGHLIGHT: 'highlight',
};

export const ITEM_UPDATE_STATUS = {
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  REJECTED: 'Rejected',
};

export const TRANSFER_TYPES = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
};

export const MENU_TYPES = {
  HOME: 'HOME',
  PROGRESS: 'PROGRESS',
  ITEMS: 'ITEMS',
  TRANSPORTS: 'TRANSPORTS',
  ORDERS: 'ORDERS',
  STOCK: 'STOCK',
  TRANSPORT_BOOKING: 'TRANSPORT_BOOKING',
  CUSTOMER_TRANSFER: 'CUSTOMER_TRANSFER',
  OPERATIONS: 'OPERATIONS',
  REPORTS: 'REPORTS',
  CONTAINER_TERMINAL: 'CONTAINER_TERMINAL',
  TICKETS: 'TICKETS',
  APPOINTMENT_SCHEDULING: 'APPOINTMENT_SCHEDULING',
  ADMINISTRATION: 'ADMINISTRATION',
  CRM: 'CRM',
};

export const WAREHOUSE_SYSTEM_TYPES = {
  WMS: 'wms',
  WCS: 'wcs',
  ESB: 'esb',
  PORTAL: 'portal',
  CRM: 'crm',
  HCMS: 'hcms',
  FMS: 'fms',
};

export const SITE_ENVIRONMENTS = {
  KLOOSTERBOER: 'Kloosterboer',
  LINEAGE_1: 'Lineage 1',
  LINEAGE_2: 'Lineage 2',
};
